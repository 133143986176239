import ptBR from 'date-fns/locale/pt-BR'
import format from 'date-fns/format'
import formatRelative from 'date-fns/formatRelative'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'

const customRelativeLocale: LocalePtBR = {
  lastWeek: 'dd/MM/yyyy',
  yesterday: "'Ontem'",
  today: "'Hoje'",
  tomorrow: "'Amanhã'",
  nextWeek: "'Próxima Semana'",
  other: 'dd/MM/yyyy',
}

const locale = {
  ...ptBR,
  formatRelative: (token: LocaleProps) => customRelativeLocale[token],
}

export const getDateExtended = (date: Date) =>
  isToday(date) || isYesterday(date)
    ? formatRelative(date, new Date(), { locale })
    : format(date, "EEEE, dd 'de' LLLL", { locale })

export const getMonthExtended = (date: Date) => {
  const extendedMonth: string = format(date, 'LLLL', { locale })
  return extendedMonth
}

export const formatHour = (value: Date) =>
  new Intl.DateTimeFormat('pt-BR', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(value))

export const formatDate = (value: Date) =>
  new Intl.DateTimeFormat('pt-BR', {
    weekday: 'long',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(value))

export const getBrazilianFormatWithHour = (date: Date) =>
  format(date, 'dd/MM/yyyy, HH:mm', { locale })

export const getBrazilianFormat = (date: Date) => format(date, 'dd/MM/yyyy', { locale })
