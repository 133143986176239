import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

export const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  ${headerStyles}
`
