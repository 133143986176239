import styled from 'styled-components'

import { Accordion as AccordionUI, Text as TextUI } from '@inter/inter-ui'
import { ReactComponent as ChevronDown } from '@/assets/accordion/chevronDown.svg'
import { ReactComponent as ChevronUp } from '@/assets/accordion/chevronUp.svg'

export const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--gray200);
`

export const Accordion = styled(AccordionUI)`
  width: 100%;

  > div {
    background-color: white;
    padding-right: 0 !important;
    padding: 0px !important;
  }
`

export const ContainerTitle = styled.div`
  height: 54px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    > svg {
      margin-right: 14px;
    }
  }
`

export const Title = styled(TextUI)`
  display: flex;
  width: 100%;
`

export const TitleScheduleShowing = styled.div`
  padding-bottom: 4px;
`

export const Subtitle = styled(TextUI)``

export const Text = styled(TextUI)``

export const IconArrowUp = styled(ChevronUp).attrs({
  height: 30,
  width: 30,
})`
  path {
  }
`

export const IconArrowDown = styled(ChevronDown).attrs({
  height: 30,
  width: 30,
})`
  path {
  }
`

export const ContainerChildren = styled.div`
  height: auto;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: -20px;
`

export const ContainerLabelClick = styled.div``

export const Footer = styled.div`
  display: flex;
  justify-content: start;
  background-color: var(--white100);
  align-items: stretch;
  button {
    padding: 0 0;
  }
`
