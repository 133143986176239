// import { getCookie } from 'inter-frontend-lib-util'

import { ENABLE_MSW } from '@/common/constants'
import * as BridgeService from '@/services/bridge'

import { FetchOptions } from './types'
import * as LoggerService from '../logger'

export async function api<T = Record<string, unknown>>(
  url: string,
  options: FetchOptions['options'],
  userAccount: string,
  userCPF: string,
): Promise<T | undefined> {
  const endpoint = `/${url}`

  try {
    const response = await BridgeService.request<T>({
      userAccount,
      endpoint,
      body: options.body,
      headers: {
        ...(options.headers || {}),
        'Content-Type': 'application/json',
        provider: 'CARD',
        'X-Inter-User-Social-Id': userCPF,
        'X-Inter-User-Bank-Account': userAccount,
      },
      method: options.method,
    })

    if (response && (response as FetchError).errors) {
      throw new Error(
        JSON.stringify({
          error: (response as FetchError).errors,
          endpoint,
        }),
      )
    }

    return response as T
  } catch (error) {
    LoggerService.error(
      {
        screenOrModule: 'ApiService',
        reason: (error as Error).message,
        userAccount,
      },
      error,
    )

    if (ENABLE_MSW) {
      const data = await fetch(`${window.location.origin}/${url}`, {
        method: options.method,
        headers: {
          ...(options.headers || {}),
          'Content-Type': 'Application/JSON',
        },
      })

      try {
        const json = await data.json()
        return Promise.resolve(json)
      } catch (e) {
        return undefined
      }
    }

    return Promise.reject(error)
  }
}
