import * as ENDPOINTS from '@/common/constants/endpoints'

import { api } from '../api'

export const getDonations = async (
  userAccount: string,
  userCPF: string,
): Promise<DonationsApiResponse | undefined> =>
  api<DonationsApiResponse>(
    ENDPOINTS.DONATION,
    {
      method: 'GET',
    },
    userAccount,
    userCPF,
  )

export const requestDonations = async (
  userAccount: string,
  userCPF: string,
  body: RequestDonations,
  iSafe: ISafe,
): Promise<RequestDonations | undefined> =>
  api<RequestDonations>(
    ENDPOINTS.DONATION,
    {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        'x-isafe-token': `${iSafe?.authenticationToken || ''}`,
        'x-isafe-value': `${iSafe?.authenticationValue || ''}`,
        'x-isafe-type': `${iSafe?.authenticationType || ''}`,
        'x-isafe-transaction-type': `${iSafe?.transactionType || ''}`,
      },
    },
    userAccount,
    userCPF,
  )

export const updateDonations = async (
  userAccount: string,
  userCPF: string,
  body: RequestDonations,
  iSafe: ISafe,
  id?: string,
): Promise<RequestDonations | undefined> =>
  api<RequestDonations>(
    `${ENDPOINTS.DONATION}/${id}`,
    {
      method: 'PUT',
      body,
      headers: {
        'Content-Type': 'application/json',
        'x-isafe-token': `${iSafe?.authenticationToken || ''}`,
        'x-isafe-value': `${iSafe?.authenticationValue || ''}`,
        'x-isafe-type': `${iSafe?.authenticationType || ''}`,
        'x-isafe-transaction-type': `${iSafe?.transactionType || ''}`,
      },
    },
    userAccount,
    userCPF,
  )

export const cancelDonations = async (
  userAccount: string,
  userCPF: string,
  iSafe: ISafe,
  id?: string,
): Promise<RequestDonations | undefined> =>
  api<RequestDonations>(
    `${ENDPOINTS.DONATION}/${id}`,
    {
      method: 'DELETE',
      headers: {
        'x-isafe-token': `${iSafe?.authenticationToken || ''}`,
        'x-isafe-value': `${iSafe?.authenticationValue || ''}`,
        'x-isafe-type': `${iSafe?.authenticationType || ''}`,
        'x-isafe-transaction-type': `${iSafe?.transactionType || ''}`,
      },
    },
    userAccount,
    userCPF,
  )
