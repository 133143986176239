import React from 'react'

import { useHistory } from 'react-router-dom'

import { Text, Button } from '@inter/inter-ui'
import { ReactComponent as Feedback } from '@/assets/feedback/signal.svg'
import { Header } from '@/components'
import { RoutesNames } from '@/routes/routes'

import * as S from './styles'

export const FeedbackAlert = () => {
  const history = useHistory<{ skipOnboardingScreen?: boolean }>()
  const redirectToMyLimitsScreen = () => {
    history.push({
      pathname: RoutesNames.ROOT,
      search: `?date=${+new Date()}`,
    })
  }
  const redirectToInformation = () => {
    history.push({
      pathname: RoutesNames.INFORMATION,
      search: `?date=${+new Date()}`,
    })
  }

  return (
    <S.Container>
      <Header
        onBackClick={redirectToMyLimitsScreen}
        onRightButtonClick={redirectToInformation}
        rightIcon="question-mark"
      >
        Doação de Cashback
      </Header>

      <S.ImageContainer>
        <Feedback />
      </S.ImageContainer>
      <S.TextContainer>
        <Text variant="headline-h1" colorWeight={500} semiBold>
          Sistema fora do ar
        </Text>
        <Text variant="body-3" colorWeight={400} semiBold>
          Desculpe, parece que o nosso sistema está fora do ar. Por favor, tente novamente em alguns
          minutos.
        </Text>
      </S.TextContainer>

      <S.Footer>
        <Button
          onClick={() => {
            history.push({
              pathname: RoutesNames.EDIT,
              state: {
                skipOnboardingScreen: history.location.state?.skipOnboardingScreen || false,
              },
              search: `?date=${+new Date()}`,
            })
          }}
        >
          Tentar novamente
        </Button>
      </S.Footer>
    </S.Container>
  )
}
