import { BridgeService } from '@/services'

export const ACCOUNT_KEY = '@inter-cdpro/account-data'
export const LINK_TERMS =
  'https://bkt-prd-projetos.s3.sa-east-1.amazonaws.com/open_finance/termos/TERMOS_DE_USO_INTER_INSTITUICAO_INICIADORA'

export const LINK_TERMS_LOCAL = '/TERMOS_DE_USO_INTER_INSTITUICAO_INICIADORA.pdf'
export const LOCAL_INSTITUTION = 'https://www.cruzvermelhamg.org.br'
export const LOCAL_INSTITUTION_WWF = 'https://www.wwf.org.br'
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'
export const IS_TEST = process.env.NODE_ENV === 'test'
export const ENABLE_MSW = IS_DEVELOPMENT && !BridgeService.inApp
