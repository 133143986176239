import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { formatBRLToNumber, formatToBRL } from '@inter/lib-util'
import { Button, Text, Input, Alert, Radio } from '@inter/inter-ui'
import { RoutesNames } from '@/routes/routes'
import { Header } from '@/components'
import * as Actions from '@/contexts/global-state/reducer/actions'
import useGlobalState from '@/contexts/global-state'
import { requestAnalytics } from '@/services/bridge'

import * as S from './styles'

export function Configuration() {
  const history = useHistory()
  const [{ valueDonation }, dispatch] = useGlobalState()
  const [selected, setSelected] = useState<string>('')
  const [show, setShow] = useState(false)
  const [validationButton, setValidationButton] = useState(true)
  const [valueCurrent, setValueCurrent] = useState('')
  const [{ appType }] = useGlobalState()
  const [valueAnalytics, setValueAnalytics] = useState('')
  useEffect(() => {
    setValueCurrent(String(formatToBRL(Number(valueDonation))))
  }, [valueDonation])
  const redirectToAccept = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_ValorDoacao_T_Botao', {
      content_id: type,
      content_name: 'proximo',
      value: valueAnalytics,
    })
    history.push({
      pathname: RoutesNames.INSTITUTION,
      search: `?date=${+new Date()}`,
    })
  }
  const redirectToInformation = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_ValorDoacao_T_Faq', {
      content_id: type,
    })
    history.push({
      pathname: RoutesNames.INFORMATION,
      search: `?date=${+new Date()}`,
    })
  }

  const redirectToHome = () => {
    history.push({
      pathname: RoutesNames.ONBOARDING,
      search: `?date=${+new Date()}`,
    })
  }

  const selectedRadio1 = () => {
    setSelected('1')
    setValueAnalytics('2.00')
    dispatch(Actions.setValue({ value: '2.00', allValue: false, customValue: false }))
    setShow(false)
    setValidationButton(false)
  }

  const selectedRadio2 = () => {
    setSelected('2')
    setValueAnalytics('10.00')
    dispatch(Actions.setValue({ value: '10.00', allValue: false, customValue: false }))
    setShow(false)
    setValidationButton(false)
  }
  const selectedRadio3 = () => {
    setSelected('3')
    setValueAnalytics('25.00')
    dispatch(Actions.setValue({ value: '25.00', allValue: false, customValue: false }))
    setShow(false)
    setValidationButton(false)
  }
  const selectedRadio4 = () => {
    setSelected('4')
    setValueAnalytics('0')
    dispatch(Actions.setValue({ value: '', allValue: true, customValue: false }))
    setShow(false)
    setValidationButton(false)
  }
  const showCurrency = () => {
    dispatch(Actions.setValue({ value: '', allValue: false, customValue: true }))
    setSelected('5')

    setShow(true)
  }
  const valueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueCurrent(event.target.value)
    const number = formatBRLToNumber(event.target.value)
    setValueAnalytics(String(number))
    dispatch(Actions.setValue({ value: String(number), allValue: false, customValue: true }))
    if (number > 0) {
      setValidationButton(false)
    } else {
      setValidationButton(true)
    }
  }

  return (
    <S.Container>
      <Header
        onBackClick={redirectToHome}
        onRightButtonClick={redirectToInformation}
        rightIcon="question-mark"
      >
        Valor da doação
      </Header>

      <S.ContainerText>
        <Text variant="headline-h3" bold>
          Qual valor máximo você gostaria de doar do seu cashback da fatura?
        </Text>
      </S.ContainerText>
      <S.ContainerRadio>
        <S.RadioBar>
          <Text variant="caption-1" colorWeight={400}>
            R$ 2,00
          </Text>
          <Radio
            id="simple-1"
            value="1"
            reversed
            checked={selected === '1'}
            onChange={selectedRadio1}
          />
        </S.RadioBar>
        <S.RadioBar>
          <Text variant="caption-1" colorWeight={400}>
            R$ 10,00
          </Text>
          <Radio
            id="simple-2"
            value="2"
            reversed
            checked={selected === '2'}
            onChange={selectedRadio2}
          />
        </S.RadioBar>
        <S.RadioBar>
          <Text variant="caption-1" colorWeight={400}>
            R$ 25,00
          </Text>
          <Radio
            id="simple-2"
            value="3"
            reversed
            checked={selected === '3'}
            onChange={selectedRadio3}
          />
        </S.RadioBar>
        <S.RadioBar>
          <Text variant="caption-1" colorWeight={400}>
            Todo o meu cashback da fatura
          </Text>
          <Radio
            id="simple-2"
            value="4"
            reversed
            checked={selected === '4'}
            onChange={selectedRadio4}
          />
        </S.RadioBar>
        <S.RadioBar>
          <Text variant="caption-1">Outro valor</Text>
          <Radio
            id="simple-2"
            value="5"
            reversed
            checked={selected === '5'}
            onChange={showCurrency}
          />
        </S.RadioBar>
      </S.ContainerRadio>
      {show === true ? (
        <>
          <S.ContainerTextCurrency>
            <Text variant="body-3">Digite o valor máximo da sua doação até R$ 999,99:</Text>
          </S.ContainerTextCurrency>
          <S.ContainerInput>
            <Input
              type="currency"
              placeholder="R$ 0,00"
              maxLength={9}
              value={valueCurrent}
              onChange={valueChange}
            />
          </S.ContainerInput>
          <S.ContainerBar>
            <S.Bar />
          </S.ContainerBar>
        </>
      ) : null}
      <S.ContainerAlert>
        <Alert type="alert" icon="warning">
          Caso o valor do seu cashback seja menor do que o selecionado, o valor total dele será
          destinado à instituição escolhida. Caso você não receba cashback, nenhum valor será doado.
        </Alert>
      </S.ContainerAlert>
      <S.WrapperFooter>
        <Button variant="primary" disabled={validationButton} onClick={redirectToAccept}>
          Continuar
        </Button>
      </S.WrapperFooter>
    </S.Container>
  )
}
