import React, { useState, useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import { Document, Page, pdfjs, DocumentProps } from 'react-pdf'

import { ReactComponent as CheckIcon } from '@/assets/terms/check.svg'
import { Text } from '@inter/inter-ui'
import { Header } from '@/components'
import { getBrazilianFormat } from '@/utils/dates'
// import { BridgeService } from '@/services'
import { LINK_TERMS_LOCAL } from '@/common/constants'
import useGlobalState from '@/contexts/global-state'

import * as S from './styles'

type LoadCallback = Required<DocumentProps>['onLoadSuccess']
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'
export const Terms = () => {
  const history = useHistory<{ skip?: boolean }>()

  const [{ activeTerms, termAccept }] = useGlobalState()

  const today = new Date(String(termAccept))
  const [numPag, setNumPag] = useState<number | null>(null)

  const onLoad: LoadCallback = useCallback((pdf) => {
    setNumPag(pdf.numPages)
  }, [])

  const redirect = () => {
    history.goBack()
  }

  return (
    <S.Container>
      <Header onBackClick={redirect}>Termos e condições</Header>

      <S.ContentContainer>
        {activeTerms === false || history.location.state?.skip === false ? null : (
          <S.ContainerDate>
            <Text variant="caption-1" bold>
              Data de aceite: <Text variant="caption-1">{getBrazilianFormat(today)}</Text>
            </Text>
            <CheckIcon />
          </S.ContainerDate>
        )}

        <Document file={LINK_TERMS_LOCAL} onLoadSuccess={onLoad}>
          {Array.from(new Array(numPag), (_, index) => (
            <Page width={window.innerWidth} key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </S.ContentContainer>
      <S.FooterContainer>
        <S.Button type="submit" onClick={history.goBack} variant="primary">
          Entendi
        </S.Button>
      </S.FooterContainer>
    </S.Container>
  )
}
