import React from 'react'

import { Icon, Icons } from '@inter/inter-ui'

import * as S from './styles'
// test 1
export type HeaderProps = {
  onBackClick?: () => void
  children?: React.ReactNode
  rightIcon?: Icons | React.ReactNode
  onRightButtonClick?: () => void
  showLeftIcon?: boolean
}

type RightIconProps = Pick<HeaderProps, 'rightIcon' | 'onRightButtonClick'>

const RightIcon = ({ rightIcon, onRightButtonClick }: RightIconProps): JSX.Element => {
  if (typeof rightIcon === 'string') {
    return <Icon name={rightIcon as Icons} color="#ff7a00" onClick={() => onRightButtonClick?.()} />
  }

  if (rightIcon) return rightIcon as JSX.Element
  return <S.CleanIcon />
}

export const Header = ({
  onBackClick,
  rightIcon,
  children,
  onRightButtonClick,
  showLeftIcon = true,
}: HeaderProps) => (
  <S.Container>
    {showLeftIcon && <Icon name="left-arrow" color="#ff7a00" onClick={onBackClick} />}

    <S.Title>{children}</S.Title>

    <RightIcon rightIcon={rightIcon} onRightButtonClick={onRightButtonClick} />
  </S.Container>
)
