import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

const FOOTER_HEIGHT = 97
export const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  position: relative;
  padding: 0 0 0 0;
  padding-bottom: ${FOOTER_HEIGHT}px;
  ${headerStyles}
`

export const ContainerText = styled.div`
  padding: 24px 24px 0 24px;
  text-align: justify;
  margin-bottom: 10px;
  span {
    color: #000000;
    font-weight: 600;
  }
`

export const ContainerTextRadio = styled.div`
  display: flex;
  flex-flow: column wrap;
  span {
    margin-bottom: 5px;
  }
  span + span {
    color: #6a6c72;
  }
`

export const ContainerTextCurrency = styled.div`
  padding: 24px 24px 0 24px;
  text-align: justify;
  span {
    color: #000000;
  }
`

export const ContainerCheckBox = styled.div`
  padding: 19px 24px 0 24px;
  text-align: justify;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #000000;
  }
`
export const Bar = styled.div`
  width: 115px;
  border-bottom: 1px solid #dedfe4;
`
export const ContainerBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerRadio = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`

export const RadioBar = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    color: #000000;
  }
  div {
    margin-top: 2px;
  }
  height: 64px;
  align-items: center;
  border-bottom: 1px solid #dedfe4;
`

export const ContainerAlert = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

export const ContainerInput = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  margin-top: 5px;
  input {
    height: 50px;
    width: 220px;
    background: #ffffff;
    padding-left: 65px;
    color: #ff7a00;
    --input-font-size: 24px;
    --input-placeholder-font-size: 24px;
    --input-placeholder-text-color: #ff7a00;
  }
`

export const WrapperFooter = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column wrap;
  padding: 24px 24px;
  justify-content: center;
  align-items: center;
  background-color: var(--white100);
  position: fixed;
  bottom: 0;
  button {
    width: 100%;
  }
`
