import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 24px;
  padding: 0 24px 0 24px;
  > span {
    margin-left: 35px;
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    min-width: 24px;
    min-height: 22px;
  }

  span {
    margin-left: 12px;
  }
`
