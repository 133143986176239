import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;

  ${headerStyles}
`

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const TextContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  margin-top: 64px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  text-align: center;
  span {
    margin-top: 32px;
  }
  span + span {
    margin-top: 16px;
    text-align: center;
  }
  a {
    color: #ff7a00;
  }
`

export const Footer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: var(--white100);

  button {
    width: 100%;
  }
`
