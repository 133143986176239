import styled from 'styled-components'

import { Accordion as AccordionUI, Text as TextUI } from '@inter/inter-ui'

export const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 0 24px;
  align-items: center;
  justify-content: start;
  width: 100%;
  min-height: 100vh;
`

export const ContainerAcordion = styled.div`
  margin-top: 70px;
`

export const Line = styled.div`
  border: 1px solid #dedfe4;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`

export const TextContainer = styled.div`
  text-align: left;
  display: flex;
  flex-flow: column wrap;
  span {
    text-align: left;
  }
  span + span {
    margin-top: 8px;
  }
  span + span + span {
    margin-top: 8px;
  }
  button {
    color: #ff7a00;
  }
`

export const Link = styled.span`
  width: auto;
  text-decoration: none;
  color: var(--primary500);
  font-size: 12px;
  font-weight: bold;
  outline: none;
  background-color: transparent;
`

export const Accordion = styled(AccordionUI)`
  width: 100%;

  > div {
    background-color: white;
    padding-right: 0 !important;
    padding: 0px !important;
  }
`

export const ContainerTitle = styled.div`
  height: 54px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    > svg {
      margin-right: 14px;
    }
  }
`

export const Title = styled(TextUI)`
  display: flex;
  width: 100%;
`

export const TitleScheduleShowing = styled.div`
  padding-bottom: 24px;
`

export const Subtitle = styled(TextUI)``

export const Text = styled(TextUI)``

export const ContainerChildren = styled.div`
  height: auto;
  width: calc(100% + 32px);
  margin-left: 16px;
  margin-top: 20px;
  margin-bottom: 50px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: start;
  background-color: var(--white100);
  align-items: stretch;
  margin-top: 8px;
  button {
    padding: 0 0;
    --button-font-size: 12px;
  }
  a {
    color: #ff7a00;
  }
`

export const ContainerLabelClick = styled.div``
