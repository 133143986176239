import React, { useEffect, useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import useGlobalState from '@/contexts/global-state'
import * as Actions from '@/contexts/global-state/reducer/actions'
import { initTheme, changeTheme, Theme } from '@inter/inter-ui'
import { BridgeService, LoggerService, ApiService } from '@/services'
import { RoutesNames } from '@/routes/routes'
import { ENABLE_MSW } from '@/common/constants'
import { HomeShimmer } from '@/components'

export const Main = () => {
  const history = useHistory()
  const [, dispatch] = useGlobalState()
  interface IKeys {
    id: string
    name: string
    image: string
  }

  const loadData = useCallback(async () => {
    let userAccount = ''
    try {
      const appInfo = await BridgeService.getAppInfo()
      LoggerService.log('Main', { appInfo })
      if (!appInfo) return

      const userInfo = await BridgeService.getUserInfo()
      userAccount = userInfo.account
      LoggerService.log('Main', { userInfo })
      dispatch(Actions.setUserAccount({ account: userInfo.account }))
      dispatch(Actions.setUserCPF({ cpf: userInfo.cpf }))
      dispatch(Actions.setAppType({ appType: userInfo.segmentation }))
      changeTheme(appInfo.tema === 'inter' || appInfo.theme === 'inter' ? Theme.PF : Theme.PJ)
      const activeValue = await ApiService.DONATIONS.getDonations(userInfo.account, userInfo.cpf)
      dispatch(Actions.setActiveTerms({ terms: activeValue?.active }))
      dispatch(Actions.setAllInstitution({ allInstitution: activeValue?.institutions }))
      const valueEdit = activeValue?.['donation-card']
      if (activeValue) {
        const valueInstitutions = activeValue?.institutions.map((index: IKeys) =>
          valueEdit?.institutions === index.id
            ? { id: index.id, name: index.name, image: index.image }
            : null,
        )
        const filtered = valueInstitutions.filter(function fil(el) {
          return el != null
        })
        dispatch(
          Actions.setUserInstitution({
            institution: String(filtered[0]?.id),
            name: String(filtered[0]?.name),
            img: String(filtered[0]?.image),
          }),
        )
        dispatch(Actions.setId({ id: valueEdit?.id }))
        dispatch(Actions.setDate({ termAccept: valueEdit?.termAccept }))
        const redirectTo = activeValue.active === false ? RoutesNames.ONBOARDING : RoutesNames.EDIT
        if (valueEdit) {
          dispatch(
            Actions.setValue({
              value: String(valueEdit?.value),
              allValue: valueEdit?.allValue,
              customValue: valueEdit?.customValue,
            }),
          )
        }
        history.push({
          pathname: redirectTo,
          search: `?date=${+new Date()}`,
        })
      }
    } catch (e) {
      LoggerService.error(
        { screenOrModule: 'Main', reason: (e as Error).message, userAccount },
        { error: e },
      )
      history.push({
        pathname: RoutesNames.ERROR,
        search: `?date=${+new Date()}`,
      })
    }
  }, [dispatch, history])

  useEffect(() => {
    initTheme(Theme.PF)
    // Timeout used because MSW not initialized
    ENABLE_MSW ? setTimeout(loadData, 1_500) : loadData()
  }, [loadData])

  return <HomeShimmer />
}
