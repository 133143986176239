import React from 'react'

import { useHistory } from 'react-router-dom'
import { interWbNavigate } from 'inter-webview-bridge'

import { formatToBRL } from '@inter/lib-util'
import useGlobalState from '@/contexts/global-state'
import { Text } from '@inter/inter-ui'
import { ReactComponent as Feedback } from '@/assets/feedback/success.svg'
import { Header } from '@/components'
import { RoutesNames } from '@/routes/routes'
import { BridgeService } from '@/services'
import { requestAnalytics } from '@/services/bridge'
import { DeepLinks } from '@/services/bridge/types'

import * as S from './styles'

export const FeedbackSucess = () => {
  const [{ valueDonation }] = useGlobalState()
  const [{ appType, nameInstitution, urlInstitution }] = useGlobalState()
  const history = useHistory<{ skipOnboardingScreen?: boolean }>()
  const redirectToHome = () => {
    history.push({
      pathname: RoutesNames.ROOT,
      search: `?date=${+new Date()}`,
    })
  }
  const redirectToInformation = () => {
    history.push({
      pathname: RoutesNames.INFORMATION,
      search: `?date=${+new Date()}`,
    })
  }
  const navigateTo = (url = '/') => {
    interWbNavigate.openDeepLink(url)
  }
  const redirectToCard = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_DC_Feedback', {
      content_id: type,
      content_name: 'doacao habilitada',
    })
    BridgeService.navigateToNativeScreen(DeepLinks.CARD)
  }

  return (
    <S.Container>
      <Header
        onBackClick={redirectToHome}
        onRightButtonClick={redirectToInformation}
        rightIcon="question-mark"
      >
        Doação de Cashback
      </Header>

      <S.ImageContainer>
        <Feedback />
      </S.ImageContainer>
      <S.TextContainer>
        <Text variant="headline-h1" colorWeight={500} semiBold>
          Doação de cashback habilitada!
        </Text>
        {Number(valueDonation) === 0 ? (
          <Text variant="body-3" colorWeight={400} semiBold>
            Ao receber o depósito dos próximos cashbacks da sua fatura, o valor máximo de{' '}
            <S.Emphasis>todo cashback da sua fatura</S.Emphasis> será destinado à{' '}
            <S.Link
              onClick={() => {
                navigateTo(urlInstitution)
              }}
            >
              {nameInstitution}
            </S.Link>
            .
          </Text>
        ) : (
          <Text variant="body-3" colorWeight={400} semiBold>
            Ao receber o depósito dos próximos cashbacks da sua fatura, o valor máximo de{' '}
            <S.Emphasis>{formatToBRL(Number(valueDonation))} </S.Emphasis>será destinado à{' '}
            <S.Link
              onClick={() => {
                navigateTo(urlInstitution)
              }}
            >
              {nameInstitution}
            </S.Link>
            .
          </Text>
        )}
        <Text variant="body-3" colorWeight={400} semiBold>
          Para editar sua doação, acesse a área de “Cartões” e vá em “Doação de cashback”.
        </Text>
      </S.TextContainer>

      <S.Footer>
        <S.Button onClick={redirectToCard}>Ir para a área de Cartões</S.Button>
      </S.Footer>
    </S.Container>
  )
}
