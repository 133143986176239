import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

const FOOTER_HEIGHT = 72
export const Container = styled.div`
  padding: 18px 0px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: ${FOOTER_HEIGHT}px;
  ${headerStyles}
`

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column wrap;
  padding: 24px 24px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
`

export const TextContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-top: 24px;
  padding-bottom: 80px;
`
