import * as Pages from '@/pages'

export const RoutesNames = {
  ROOT: '/',
  ONBOARDING: '/onboarding',
  INFORMATION: '/information',
  ERROR: '/error',
  INSTITUTION: '/institution',
  CONFIGURATION: '/configuration',
  ACCEPT: '/accept',
  SUCESS: '/sucess',
  ALERT: '/alert',
  EDIT: '/edit',
  CANCEL: '/cancel',
  EDITCONFIGURATION: '/edit-configuration',
  EDITACCEPT: '/edit-accept',
  EDITSUCESS: '/edit-sucess',
  TERMS: '/terms',
  EDITINSTITUTION: '/edit-institution',
}
export default [
  { path: RoutesNames.ROOT, exact: true, component: Pages.Main },
  { path: RoutesNames.ONBOARDING, exact: true, component: Pages.Onboarding },
  { path: RoutesNames.INFORMATION, exact: true, component: Pages.Information },
  { path: RoutesNames.INSTITUTION, exact: true, component: Pages.Institution },
  { path: RoutesNames.EDITINSTITUTION, exact: true, component: Pages.EditInstitution },
  { path: RoutesNames.ERROR, exact: true, component: Pages.FeedbackError },
  { path: RoutesNames.CONFIGURATION, exact: true, component: Pages.Configuration },
  { path: RoutesNames.ACCEPT, exact: true, component: Pages.Accept },
  { path: RoutesNames.SUCESS, exact: true, component: Pages.FeedbackSucess },
  { path: RoutesNames.EDIT, exact: true, component: Pages.EditOnboarding },
  { path: RoutesNames.CANCEL, exact: true, component: Pages.FeedbackCancel },
  { path: RoutesNames.EDITCONFIGURATION, exact: true, component: Pages.EditConfiguration },
  { path: RoutesNames.EDITACCEPT, exact: true, component: Pages.EditAccept },
  { path: RoutesNames.EDITSUCESS, exact: true, component: Pages.FeedbackEdit },
  { path: RoutesNames.TERMS, exact: true, component: Pages.Terms },
  { path: RoutesNames.ALERT, exact: true, component: Pages.FeedbackAlert },
]
