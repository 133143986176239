import { createReducer } from 'cdpro-redux-utils'

import { RootState } from './types'
import { setWelcomeMessage } from './actions'
import * as Actions from './actions'

export const INITIAL_STATE: RootState = {
  message: 'Ready to start developing!',
  userAccount: '',
  userCPF: '',
  userID: '',
  activeTerms: false,
  termAccept: '',
  allInstitution: [],
  userInstitution: '',
  nameInstitution: '',
  imgInstitution: '',
  urlInstitution: '',
  appType: '',
  valueDonation: '',
  valueDonationAccept: '',
  setAllValueAccept: false,
  setCustomValueAccept: false,
  accountType: 'PESSOA_NATURAL',
  setAllValue: false,
  setCustomValue: false,
  isPersonalAccount: true,
  isAppPF: false,
  mobilePlatform: undefined,
  isAppPJ: false,
}

const { reducer, addCase } = createReducer<RootState>(INITIAL_STATE)

addCase(setWelcomeMessage, (draft, action) => {
  const { message } = action.payload

  draft.message = message
})

addCase(Actions.setAppType, (draft, action) => {
  draft.appType = action.payload.appType
})

addCase(Actions.setDate, (draft, action) => {
  draft.termAccept = action.payload.termAccept
})

addCase(Actions.setMobilePlatform, (draft, action) => {
  draft.mobilePlatform = action.payload
})

addCase(Actions.setUserAccount, (draft, action) => {
  draft.userAccount = action.payload.account
})

addCase(Actions.setUserCPF, (draft, action) => {
  draft.userCPF = action.payload.cpf
})

addCase(Actions.setId, (draft, action) => {
  draft.userID = action.payload.id
})

addCase(Actions.setActiveTerms, (draft, action) => {
  draft.activeTerms = action.payload.terms
})

addCase(Actions.setUserInstitution, (draft, action) => {
  draft.userInstitution = action.payload.institution
})
addCase(Actions.setUserInstitution, (draft, action) => {
  draft.nameInstitution = action.payload.name
})
addCase(Actions.setUserInstitution, (draft, action) => {
  draft.imgInstitution = action.payload.img
})
addCase(Actions.setUserInstitution, (draft, action) => {
  draft.urlInstitution = action.payload.url
})
addCase(Actions.setAllInstitution, (draft, action) => {
  draft.allInstitution = action.payload.allInstitution
})

addCase(Actions.setValue, (draft, action) => {
  draft.valueDonation = action.payload.value
})
addCase(Actions.setValue, (draft, action) => {
  draft.setAllValue = action.payload.allValue
})
addCase(Actions.setValue, (draft, action) => {
  draft.setCustomValue = action.payload.customValue
})

addCase(Actions.setValueAccept, (draft, action) => {
  draft.valueDonationAccept = action.payload.valueAccept
})
addCase(Actions.setValueAccept, (draft, action) => {
  draft.setAllValueAccept = action.payload.allValueAccept
})
addCase(Actions.setValueAccept, (draft, action) => {
  draft.setCustomValueAccept = action.payload.customValueAccept
})
export default reducer
