import React from 'react'

import { Text } from '@inter/inter-ui'

import * as S from './styles'

type DescriptionProps = {
  icon?: React.ReactNode
  title: string
  description: React.ReactNode
}

export const Description = ({ icon, title, description }: DescriptionProps) => (
  <S.Container>
    <S.Title>
      {icon}
      <Text variant="headline-h3">{title}</Text>
    </S.Title>
    {description}
  </S.Container>
)
