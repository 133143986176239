import React, { useState, ReactNode } from 'react'

import { requestAnalytics } from '@/services/bridge'
import useGlobalState from '@/contexts/global-state'

import * as S from './styles'

interface IAccordionProps {
  titleAccordion: string
  children: ReactNode
  handle: string
}

type CustomLabelProps = {
  title: string
  expanded: boolean
}

const CustomLabel = ({ title, expanded }: CustomLabelProps) => (
  <S.ContainerTitle>
    <div>
      <S.Title variant="body-3" bold colorWeight={500}>
        {title}
      </S.Title>
    </div>
    {expanded ? <S.IconArrowUp /> : <S.IconArrowDown />}
  </S.ContainerTitle>
)

export const ScheduleAccordion = ({ titleAccordion, children, handle }: IAccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [{ appType }] = useGlobalState()
  const handleCloseAccordion = () => {
    setExpanded(!expanded)
    setTimeout(() => {
      window.scroll(0, 0)
    }, 500)
  }
  const handleDoubt = (doubt: string) => {
    !expanded ? setExpanded(!expanded) : null
    requestAnalytics('C_DoacaoCashback_Faq_T_Duvida', {
      content_id: appType || '',
      content_name: doubt,
    })
  }

  return (
    <S.Container role="main">
      {expanded && (
        <S.ContainerLabelClick onClick={handleCloseAccordion}>
          <CustomLabel title={titleAccordion} expanded={expanded} />
        </S.ContainerLabelClick>
      )}
      <S.Accordion
        labelComponent={
          expanded ? (
            <S.TitleScheduleShowing />
          ) : (
            <CustomLabel expanded={expanded} title={titleAccordion} />
          )
        }
        expanded={expanded}
        onClick={() => {
          handleDoubt(handle)
        }}
      >
        <S.ContainerChildren>{children}</S.ContainerChildren>
      </S.Accordion>
    </S.Container>
  )
}
