import { action } from 'cdpro-redux-utils'

import { Platforms } from './types'

export const setWelcomeMessage = action<{ message: string }>('@welcome/SET_MESSAGE')
export const Types = {
  User: {
    SET_USER_ACCOUNT: '@user/SET_USER_ACCOUNT',
  },
  CPF: {
    SET_USER_CPF: '@user/SET_USER_CPF',
  },
  ID: {
    SET_USER_ID: '@user/SET_USER_ID',
  },
  INSTITUTION: {
    SET_INSTITUTION: '@user/SET_INSTITUTION',
  },
  ALL_INSTITUTION: {
    SET_ALL_INSTITUTION: '@user/SET_ALL_INSTITUTION',
  },
  ACTIVE_TERMS: {
    SET_ACTIVE_TERMS: '@user/SET_ACTIVE_TERMS',
  },
  TERM_ACCEPT: {
    SET_DATE: '@user/SET_DATE',
  },
  DONATION: {
    SET_VALUE: '@donation/SET_VALUE',
    ALL_VALUE: '@donation/ALL_VALUE',
    CUSTOM_VALUE: '@donation/CUSTOM_VALUE',
  },
  ACCEPTDONATION: {
    SET_VALUE_ACCEPT: '@donation/SET_VALUE_ACCEPT',
    ALL_VALUE_ACCEPT: '@donation/ALL_VALUE_ACCEPT',
    CUSTOM_VALUE_ACCEPT: '@donation/CUSTOM_VALUE_ACCEPT',
  },
  MobilePlatform: {
    SET_MOBILE_PLATFORM: '@MobilePlatform/SET_MOBILE_PLATFORM',
  },

  App: {
    SET_TYPE: '@app/SET_TYPE',
  },
}
export const setUserAccount = action<{ account: string }>(Types.User.SET_USER_ACCOUNT)

export const setUserCPF = action<{ cpf: string }>(Types.CPF.SET_USER_CPF)

export const setId = action<{ id: string | undefined }>(Types.ID.SET_USER_ID)

export const setActiveTerms = action<{ terms: boolean | undefined }>(
  Types.ACTIVE_TERMS.SET_ACTIVE_TERMS,
)

export const setValue = action<{ value: string; customValue: boolean; allValue: boolean }>(
  Types.DONATION.SET_VALUE,
)

export const setUserInstitution = action<{
  institution: string
  name: string
  img?: string
  url?: string
}>(Types.INSTITUTION.SET_INSTITUTION)
export const setAllInstitution = action<{ allInstitution: [] | undefined }>(
  Types.ALL_INSTITUTION.SET_ALL_INSTITUTION,
)

export const setValueAccept = action<{
  valueAccept: string
  customValueAccept: boolean
  allValueAccept: boolean
}>(Types.ACCEPTDONATION.SET_VALUE_ACCEPT)

export const setAppType = action<{ appType: string | undefined }>(Types.App.SET_TYPE)

export const setDate = action<{ termAccept: string | undefined }>(Types.TERM_ACCEPT.SET_DATE)

export const setMobilePlatform = action<Platforms>(Types.MobilePlatform.SET_MOBILE_PLATFORM)
