import React from 'react'

import { Header } from '@/components'
import { Skeleton } from '@inter/inter-ui'
import { BridgeService } from '@/services'

import * as S from './styles'

export const HomeShimmer = () => (
  <>
    <Header onRightButtonClick={() => BridgeService.navigateHome()}>Doação de Cashback</Header>

    <S.Container>
      <Skeleton width="100%" height="256px" />
      <S.TextContainer>
        <Skeleton width="100%" height="61px" />
        <Skeleton width="100%" height="51px" style={{ marginTop: '8px' }} />
      </S.TextContainer>
      <S.Footer>
        <Skeleton width="100%" height="48px" style={{ marginTop: '15px' }} />
        <Skeleton width="100%" height="48px" style={{ marginTop: '15px ' }} />
      </S.Footer>
    </S.Container>
  </>
)

export default HomeShimmer
