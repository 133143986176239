import React from 'react'

import { useHistory } from 'react-router-dom'
import { interWbNavigate } from 'inter-webview-bridge'

import useGlobalState from '@/contexts/global-state'
import { Text, Button } from '@inter/inter-ui'
import { Header } from '@/components'
import { RoutesNames } from '@/routes/routes'
import { ScheduleAccordion } from '@/components/Accordion'
import { requestAnalytics } from '@/services/bridge'
import { LOCAL_INSTITUTION } from '@/common/constants'

import * as S from './styles'

export function Information() {
  const history = useHistory<{ skip?: boolean }>()
  const [{ appType }] = useGlobalState()
  const redirect = () => {
    history.goBack()
  }
  const handleTag = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_Faq_T_SaibaMais', {
      content_id: type,
      content_name: 'sobre a instituicao',
    })
  }
  /*
  const handleRule = () => {
    requestAnalytics('C_DoacaoCashback_Faq_T_SaibaMais', {
      content_id: appType || '',
      content_name: 'regras de cashback',
    })
  }
  */
  const redirectToTerms = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_Faq_T_SaibaMais', {
      content_id: type,
      content_name: 'termos',
    })
    history.push({
      pathname: RoutesNames.TERMS,
      state: {
        skip: history.location.state?.skip || false,
      },
      search: `?date=${+new Date()}`,
    })
  }
  const navigateTo = (url = '/') => {
    interWbNavigate.openDeepLink(url)
  }

  return (
    <S.Container role="main">
      <Header onBackClick={redirect} rightIcon={false}>
        Dúvidas frequentes
      </Header>
      <S.ContainerAcordion>
        <ScheduleAccordion
          titleAccordion="O que é a funcionalidade doação de cashback?"
          handle="O que é a funcionalidade doação de cashback?"
        >
          <S.TextContainer>
            <Text variant="caption-1" colorWeight={400}>
              Ao ativar a funcionalidade, os valores recebidos de cashback da sua fatura, seguindo
              as regras e condições, serão transferidos diretamente para a conta da instituição de
              forma recorrente. O valor será transferido assim que receber o cashback da fatura, de
              forma automática.
            </Text>
          </S.TextContainer>
        </ScheduleAccordion>
        <ScheduleAccordion
          titleAccordion="Sobre os valores de doação"
          handle="Sobre os valores de doação"
        >
          <S.TextContainer>
            <Text variant="caption-1" colorWeight={400}>
              Não existe um valor mínimo para doação, apenas o valor máximo, que é de R$ 999,99.
            </Text>
          </S.TextContainer>
        </ScheduleAccordion>

        <ScheduleAccordion
          titleAccordion="Posso doar de outra forma?"
          handle="Posso doar de outra forma?"
        >
          <S.TextContainer>
            <Text variant="caption-1" colorWeight={400}>
              No momento, possuímos apenas a funcionalidade de doação do cashback da fatura, mas já
              estamos trabalhando para oferecer outras opções para facilitar ações solidárias e
              sustentáveis.
            </Text>
          </S.TextContainer>
        </ScheduleAccordion>
        <ScheduleAccordion
          titleAccordion="Onde posso consultar os valores que já doei?"
          handle="Onde posso consultar os valores que já doei?"
        >
          <S.TextContainer>
            <Text variant="caption-1" colorWeight={400}>
              A doação será realizada assim que receber o cashback da fatura e o valor será
              transferido diretamente para a conta da instituição. A transferência ficará registrada
              em seu extrato e você receberá um e-mail com a confirmação.
            </Text>
          </S.TextContainer>
        </ScheduleAccordion>
        <ScheduleAccordion
          titleAccordion="A doação tem dedução de imposto?"
          handle="A doação tem dedução de imposto?"
        >
          <S.TextContainer>
            <Text variant="caption-1" colorWeight={400}>
              Essa modalidade de doação não tem dedução de impostos. Dessa forma, o Inter não possui
              responsabilidades sobre o imposto de renda do cliente.
            </Text>
          </S.TextContainer>
        </ScheduleAccordion>
        <ScheduleAccordion
          titleAccordion="Posso cancelar ou editar o valor e a instituição da minha doação recorrente?"
          handle="Posso cancelar ou editar o valor e a instituição da minha doação recorrente?"
        >
          <S.TextContainer>
            <Text variant="caption-1" colorWeight={400}>
              No momento, você pode cancelar ou editar apenas o valor da sua doação. Para alterações
              que ocorrerem na data de vencimento da fatura, o cancelamento ou a edição passam a
              valer no ciclo da fatura seguinte. Em breve teremos mais opções de instituição para
              você escolher. Acesse o nosso artigo no blog e saiba mais sobre a alteração nas
              preferências da doação.
            </Text>
          </S.TextContainer>
        </ScheduleAccordion>
        <ScheduleAccordion
          titleAccordion="Quais são os termos e condições da funcionalidade doação de cashback?"
          handle="Quais são os termos e condições da funcionalidade doação de cashback?"
        >
          <S.TextContainer>
            <Text variant="caption-1" colorWeight={400}>
              Acesse os Termos e condições da doação de cashback para mais informações.
            </Text>
          </S.TextContainer>
          <S.Footer>
            <Button variant="link" onClick={redirectToTerms}>
              Termos e condições
            </Button>
          </S.Footer>
        </ScheduleAccordion>
        <ScheduleAccordion
          titleAccordion="Quais as regras para receber cashback?"
          handle="Quais as regras para receber cashback?"
        >
          <S.TextContainer>
            <Text variant="caption-1" colorWeight={400}>
              Clientes Mastercard Gold e Platinum devem estar com o débito automático ativo e com
              pagamento em dia, sem parcelamento da fatura, parcelamento compulsório ou crédito
              rotativo. Clientes Mastercard Black devem estar com o pagamento em dia, sem
              parcelamento da fatura ou crédito rotativo.
            </Text>
            <Text variant="caption-1" colorWeight={400}>
              Clientes Mastercard Black devem estar com o pagamento em dia, sem parcelamento da
              fatura ou crédito rotativo.
            </Text>
          </S.TextContainer>
        </ScheduleAccordion>
        <ScheduleAccordion
          titleAccordion="Como o cashback é calculado?"
          handle="Como o cashback é calculado?"
        >
          <S.TextContainer>
            <Text variant="caption-1" colorWeight={400}>
              O cashback é calculado sobre o pagamento do valor total da sua fatura de cartão de
              crédito, com base na sua modalidade de cartão:
            </Text>
            <Text variant="caption-1" colorWeight={400}>
              <li>
                Cartão Inter Mastercard Gold: 0,25% de cashback sobre o valor das compras lançadas
                na fatura.
              </li>
              <li>
                Cartão Inter Mastercard Platinum: 0,50% de cashback sobre o valor das compras
                lançadas na fatura.
              </li>
              <li>
                Cartão Inter Mastercard Black: 1,00% de cashback sobre o valor das compras lançadas
                na fatura.
              </li>
              <li>
                Cartão Inter Mastercard Win: 1,25% de cashback sobre o valor das compras lançadas na
                fatura.
              </li>
            </Text>
            <Text variant="caption-1" colorWeight={400}>
              Além disso, os clientes Inter Pass possuem 0,25% de cashback adicional na fatura do
              cartão.
            </Text>
          </S.TextContainer>
        </ScheduleAccordion>
        <ScheduleAccordion
          titleAccordion="Como sei quais valores de cashback da fatura já ganhei?"
          handle="Como sei quais valores de cashback da fatura já ganhei?"
        >
          <S.TextContainer>
            <Text variant="caption-1" colorWeight={400}>
              No app do Inter, você pode conferir o histórico de valores recebidos relacionados à
              fatura do cartão e Inter Pass. Para acessar o Extrato de Cashback, basta seguir o
              passo a passo:
            </Text>
            <Text variant="caption-1" colorWeight={400}>
              <ol>
                <li>Acesse o app do Inter;</li>
                <li>Vá ao menu da tela inicial;</li>
                <li>Selecione a opção “Cashback”.</li>
              </ol>
            </Text>
            <Text variant="caption-1" colorWeight={400}>
              Pronto! Agora é só conferir os valores que você ganhou de cashback.
            </Text>
          </S.TextContainer>
        </ScheduleAccordion>
        <ScheduleAccordion titleAccordion="Sobre a instituição" handle="Sobre a instituição">
          <S.TextContainer>
            <Text variant="caption-1" bold colorWeight={400}>
              Cruz Vermelha Brasileira - Filial MG
            </Text>
            <Text variant="caption-1" colorWeight={400}>
              A Cruz Vermelha Brasil - Minas Gerais é uma instituição sem fins lucrativos, de
              utilidade pública, que tem a missão de atenuar o sofrimento humano, de forma imparcial
              e independente, sendo auxiliar dos poderes públicos e atuando em favor das populações
              em situação de vulnerabilidade.
            </Text>
            <Text variant="caption-1" colorWeight={400}>
              A doação será direcionada para a sede de Minas Gerais, mas os valores serão destinados
              para todo o Brasil.
            </Text>
          </S.TextContainer>
          <S.Footer onClick={handleTag}>
            <S.Link
              onClick={() => {
                navigateTo(LOCAL_INSTITUTION)
              }}
            >
              Saiba mais
            </S.Link>
          </S.Footer>
          <S.Line />

          <S.TextContainer>
            <Text variant="caption-1" bold colorWeight={400}>
              WWF - Brasil
            </Text>
            <Text variant="caption-1" colorWeight={400}>
              O WWF - Brasil é uma ONG brasileira que há 25 anos atua coletivamente com parceiros da
              sociedade civil, academia, governos e empresas em todo país para combater a degradação
              socioambiental e defender a vida das pessoas e da natureza.
            </Text>
            <Text variant="caption-1" colorWeight={400}>
              Estão conectados numa rede interdependente que busca soluções urgentes para a
              emergência climática. Juntos em defesa das pessoas e da natureza.
            </Text>
          </S.TextContainer>
          <S.Footer onClick={handleTag}>
            <S.Link
              onClick={() => {
                navigateTo('https://www.wwf.org.br/')
              }}
            >
              Saiba mais
            </S.Link>
          </S.Footer>
        </ScheduleAccordion>
        <ScheduleAccordion
          titleAccordion="O que irão fazer com o valor da doação?"
          handle="O que irão fazer com o valor da doação?"
        >
          <S.TextContainer>
            <Text variant="caption-1" colorWeight={400}>
              A doação será direcionada para a Cruz Vermelha Brasileira - Minas Gerais, que irá
              utilizar os recursos para prestar ajuda humanitária em desastres e emergências,
              doações, assistência social a jovens, idosos e famílias em situação de risco social.
            </Text>
          </S.TextContainer>
        </ScheduleAccordion>
      </S.ContainerAcordion>
    </S.Container>
  )
}
