import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { Text, Button, Icon as UIIcon } from '@inter/inter-ui'
import { Header } from '@/components/Header'
import { RoutesNames } from '@/routes/routes'
import { BridgeService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import { requestAnalytics } from '@/services/bridge'
import { ReactComponent as HomeImage } from '@/assets/onboarding/image.svg'

import * as S from './styles'

export function Onboarding() {
  const [{ userAccount }] = useGlobalState()
  const history = useHistory()
  const [{ appType }] = useGlobalState()

  useEffect(() => {
    const type = String(appType)
    requestAnalytics('C_Cartoes_Home_T_Produtos', {
      content_id: type,
    })
  })

  const redirectToInformation = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_Onboarding_T_Botao', {
      content_id: type,
      content_name: 'saiba mais',
    })
    history.push({
      pathname: RoutesNames.INFORMATION,
      search: `?date=${+new Date()}`,
    })
  }

  const redirectToConfiguration = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_Onboarding_T_Botao', {
      content_id: type,
      content_name: 'quero doar',
    })

    history.push({
      pathname: RoutesNames.CONFIGURATION,
      search: `?date=${+new Date()}`,
    })
  }

  return (
    <S.Container role="main">
      <Header
        onBackClick={() => BridgeService.requestGoBack(userAccount)}
        rightIcon="house"
        onRightButtonClick={() => BridgeService.navigateHome()}
      >
        Doação de Cashback
      </Header>
      <S.ImageContainer>
        <HomeImage />
      </S.ImageContainer>
      <S.TextContainer>
        <Text variant="headline-h1" colorWeight={500} semiBold>
          Doe seu cashback e ajude uma causa
        </Text>

        <Text variant="body-3" colorWeight={400} semiBold>
          Agora, você pode doar mensalmente todo ou uma parte do seu cashback da fatura para uma
          instituição social parceira.
        </Text>
      </S.TextContainer>

      <S.Footer>
        <Button
          variant="primary"
          onClick={redirectToConfiguration}
          icon={<UIIcon name="arrow-right" color="var(--white100)" />}
        >
          Quero doar
        </Button>
        <Button
          variant="secondary"
          onClick={redirectToInformation}
          icon={<UIIcon name="arrow-right" color="#ff7a00" />}
        >
          Quero saber mais sobre a doação
        </Button>
      </S.Footer>
    </S.Container>
  )
}
