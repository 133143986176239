import React, { useState, useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import { formatToBRL } from '@inter/lib-util'
import { Text, Button, Icon as UIIcon, BottomSheet, ISafeBottomSheet } from '@inter/inter-ui'
import { Header } from '@/components/Header'
import { RoutesNames } from '@/routes/routes'
import { ApiService, BridgeService, LoggerService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import { requestAnalytics } from '@/services/bridge'

import * as S from './styles'

export function EditOnboarding() {
  const history = useHistory()
  const [isafeShowBottomSheet, isafeSetShowBottomSheet] = useState<boolean>(false)
  const [showBottomSheetMessageConfirm, setShowBottomSheetMessageConfirm] = useState<boolean>(false)
  const [showBottomSheetMessage, setShowBottomSheetMessage] = useState<boolean>(false)
  const [showBottomSheetMessageEnabled, setShowBottomSheetMessageEnabled] = useState<boolean>(false)
  const [{ appType, userAccount, userID, userCPF, nameInstitution, imgInstitution }] =
    useGlobalState()
  const [{ valueDonation }] = useGlobalState()

  const navigateToSucess = () => {
    setShowBottomSheetMessageEnabled(true)
  }
  const redirectCancelSucess = async () => {
    setShowBottomSheetMessageEnabled(false)
    history.push({
      pathname: RoutesNames.CANCEL,
      search: `?date=${+new Date()}`,
    })
  }

  const validationISafe = async () => {
    const type = String(appType)
    setShowBottomSheetMessageConfirm(false)
    try {
      const iSafeToken = await BridgeService.authenticateRequestCashback()
      isafeSetShowBottomSheet(true)
      if (iSafeToken) {
        const deleteValue = await ApiService.DONATIONS.cancelDonations(
          userAccount,
          userCPF,
          iSafeToken as ISafe,
          userID,
        )
        if (deleteValue) {
          requestAnalytics('C_DoacaoCashback_Onboarding_T_Botao', {
            content_id: type,
            content_name: 'Cancelar doacão recorrente',
          })
          isafeSetShowBottomSheet(false)
          navigateToSucess()
        }
      }
    } catch (e) {
      LoggerService.error(
        { screenOrModule: 'Main', reason: (e as Error).message, userAccount },
        { error: e },
      )
      history.push({
        pathname: RoutesNames.ERROR,
        search: `?date=${+new Date()}`,
      })
    }
  }
  const redirectToModal = () => {
    setShowBottomSheetMessage(true)
  }
  const iSafeAuthenticate = useCallback(
    (): Promise<void> =>
      // Função que fará a autenticação via i-safe.
      // A função deve retornar uma promise.
      Promise.resolve(),
    [],
  )

  const onAuthenticateComplete = useCallback((status: boolean) => {
    if (status) {
      // Caso a autenticação tenha sucesso fazer algo aqui
    }

    // Caso a falhe a autenticação, fazer algo aqui
  }, [])

  const redirectToConfiguration = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_Onboarding_T_Botao', {
      content_id: type,
      content_name: 'Editar preferências de doacao',
    })
    history.push({
      pathname: RoutesNames.EDITCONFIGURATION,
      search: `?date=${+new Date()}`,
    })
  }
  const redirectToInformation = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_ValorDoacao_T_Faq', {
      content_id: type,
    })
    history.push({
      pathname: RoutesNames.INFORMATION,
      search: `?date=${+new Date()}`,
    })
  }
  const handleMessageConfirm = () => {
    setShowBottomSheetMessage(false)
    setShowBottomSheetMessageConfirm(true)
  }

  return (
    <S.Container role="main">
      <Header
        onBackClick={() => BridgeService.requestGoBack(userAccount)}
        rightIcon="question-mark"
        onRightButtonClick={redirectToInformation}
      >
        Doação de Cashback
      </Header>
      <S.ImageContainer>
        <S.CardImage src={require(`../../assets/editOnboarding/${imgInstitution}.png`)} />
      </S.ImageContainer>
      <S.TextContainer>
        <Text variant="headline-h1" colorWeight={500} semiBold>
          Configuração da doação do seu cashback
        </Text>
        {Number(valueDonation) === 0 ? (
          <Text variant="body-3" colorWeight={400} semiBold>
            Hoje, você doa <S.Emphasis>todo o cashback da sua fatura</S.Emphasis> para a{' '}
            <S.Emphasis>{nameInstitution}.</S.Emphasis>
          </Text>
        ) : (
          <Text variant="body-3" colorWeight={400} semiBold>
            Hoje, você doa o valor máximo de{' '}
            <S.Emphasis>{formatToBRL(Number(valueDonation))}</S.Emphasis> do cashback da sua fatura
            para a <S.Emphasis>{nameInstitution}.</S.Emphasis>
          </Text>
        )}
      </S.TextContainer>

      <S.Footer>
        <Button
          variant="primary"
          onClick={redirectToConfiguration}
          icon={<UIIcon name="arrow-right" color="var(--white100)" />}
        >
          Editar doação
        </Button>
        <Button
          variant="secondary"
          onClick={redirectToModal}
          icon={<UIIcon name="arrow-right" color="#ff7a00" />}
        >
          Cancelar doação recorrente
        </Button>
      </S.Footer>

      {showBottomSheetMessage && (
        <BottomSheet onClose={() => setShowBottomSheetMessage(false)}>
          <S.ContainerTextModal>
            <Text variant="headline-h3" bold>
              Cancelar doação recorrente
            </Text>

            <Text variant="body-3" colorWeight={400} semiBold>
              O cancelamento da doação pode ser feito até o dia de vencimento da sua fatura. Caso
              contrário, ela será cancelada no próximo ciclo.
            </Text>

            <Button variant="primary" onClick={handleMessageConfirm}>
              Quero cancelar
            </Button>
          </S.ContainerTextModal>
        </BottomSheet>
      )}
      {isafeShowBottomSheet && (
        <ISafeBottomSheet
          onClose
          authenticate={iSafeAuthenticate}
          onComplete={onAuthenticateComplete}
          description="Para sua segurança estamos autenticando a operação com o i-safe."
          title="Autenticação"
        />
      )}
      {showBottomSheetMessageConfirm && (
        <BottomSheet onClose={() => setShowBottomSheetMessageConfirm(false)}>
          <S.ContainerTextModal>
            <Text variant="headline-h3" bold>
              Cancelar doação recorrente
            </Text>

            <Text variant="body-3" colorWeight={400} semiBold>
              Clique em confirmar para autenticar o cancelamento da doação recorrente de cashback
              pelo iSafe.
            </Text>

            <Button variant="primary" onClick={validationISafe}>
              Confirmar
            </Button>
          </S.ContainerTextModal>
        </BottomSheet>
      )}
      {showBottomSheetMessageEnabled && (
        <BottomSheet onClose={() => setShowBottomSheetMessageEnabled(false)}>
          <S.ContainerTextModal>
            <Text variant="headline-h3" bold>
              Doação recorrente cancelada
            </Text>

            <Text variant="body-3" colorWeight={400} semiBold>
              A doação recorrente de cashback da fatura foi cancelada com sucesso.
            </Text>

            <Button variant="primary" onClick={redirectCancelSucess}>
              Entendi
            </Button>
          </S.ContainerTextModal>
        </BottomSheet>
      )}
    </S.Container>
  )
}
