import React, { useState, useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import useGlobalState from '@/contexts/global-state'
import { ReactComponent as CheckArrows } from '@/assets/accept/checkArrows.svg'
import { ReactComponent as Credit } from '@/assets/accept/credit.svg'
import { ReactComponent as Canceled } from '@/assets/accept/canceled.svg'
import { Text, Button, Checkbox, BottomSheet, ISafeBottomSheet } from '@inter/inter-ui'
import { Header, Description } from '@/components'
import { RoutesNames } from '@/routes/routes'
import { ApiService, LoggerService, BridgeService } from '@/services'
import { requestAnalytics } from '@/services/bridge'

import * as S from './styles'

export function Accept() {
  const history = useHistory()
  const [terms, setTerms] = useState({ agree: false, read: false })
  const [isafeShowBottomSheet, isafeSetShowBottomSheet] = useState<boolean>(false)
  const [showBottomSheetMessage, setShowBottomSheetMessage] = useState<boolean>(false)
  const [showBottomSheetMessageEnabled, setShowBottomSheetMessageEnabled] = useState<boolean>(false)
  const [{ appType }] = useGlobalState()
  const [
    {
      userAccount,
      userCPF,
      valueDonation,
      setAllValue,
      setCustomValue,
      userInstitution,
      nameInstitution,
    },
  ] = useGlobalState()
  const navigateToSucess = () => {
    setShowBottomSheetMessageEnabled(true)
  }
  const validationIsafe = async () => {
    const type = String(appType)
    try {
      setShowBottomSheetMessage(false)
      const iSafeToken = await BridgeService.authenticateRequestCashback()
      isafeSetShowBottomSheet(true)
      if (iSafeToken) {
        const activeValue = await ApiService.DONATIONS.requestDonations(
          userAccount,
          userCPF,
          {
            socialId: userCPF,
            account: userAccount,
            provider: 'CARD',
            value: Number(valueDonation),
            allValue: setAllValue,
            customValue: setCustomValue,
            recurrent: true,
            institutions: userInstitution,
          },
          iSafeToken as ISafe,
        )
        if (activeValue) {
          requestAnalytics('C_DoacaoCashback_Aceite_T_Ativacao', {
            content_id: type,
            content_name: 'Ativar doacao de cashback',
          })
          isafeSetShowBottomSheet(false)
          navigateToSucess()
        }
      }
    } catch (e) {
      LoggerService.error(
        { screenOrModule: 'Main', reason: (e as Error).message, userAccount },
        { error: e },
      )
      history.push({
        pathname: RoutesNames.ERROR,
        search: `?date=${+new Date()}`,
      })
    }
  }

  type DescriptionTextProps = {
    children: React.ReactNode
    bold?: boolean
  }

  const DescriptionText = ({ children, bold = false }: DescriptionTextProps) => (
    <Text variant="caption-1" bold={bold}>
      {children}
    </Text>
  )

  const redirectToDonation = () => {
    history.push({
      pathname: RoutesNames.INSTITUTION,
      search: `?date=${+new Date()}`,
    })
  }
  const redirectToSucess = () => {
    setShowBottomSheetMessageEnabled(false)
    history.push({
      pathname: RoutesNames.SUCESS,
      search: `?date=${+new Date()}`,
    })
  }
  const redirectToInformation = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_ValorDoacao_T_Faq', {
      content_id: type,
    })
    history.push({
      pathname: RoutesNames.INFORMATION,
      search: `?date=${+new Date()}`,
    })
  }
  const iSafeAuthenticate = useCallback(
    (): Promise<void> =>
      // Função que fará a autenticação via i-safe.
      // A função deve retornar uma promise.
      Promise.resolve(),
    [],
  )

  const onAuthenticateComplete = useCallback((status: boolean) => {
    if (status) {
      // Caso a autenticação tenha sucesso fazer algo aqui
    }

    // Caso a falhe a autenticação, fazer algo aqui
  }, [])
  const redirectToTerms = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_Termos_T_Visualizar', {
      content_id: type,
    })
    history.push({
      pathname: RoutesNames.TERMS,
      search: `?date=${+new Date()}`,
    })
  }

  return (
    <S.Container>
      <Header
        onBackClick={redirectToDonation}
        onRightButtonClick={redirectToInformation}
        rightIcon="question-mark"
      >
        Doação recorrente
      </Header>
      <S.ContainerText>
        <Text variant="headline-h1" bold>
          Doe sempre que receber cashback da fatura
        </Text>
      </S.ContainerText>
      <S.ContainerDescription>
        <Description
          icon={<CheckArrows />}
          title="Esta doação é recorrente"
          description={
            <DescriptionText>
              Ao receber o cashback da fatura, o valor configurado será direcionado para a conta da{' '}
              {nameInstitution}.
            </DescriptionText>
          }
        />
        <Description
          icon={<Canceled />}
          title="Cancele quando quiser"
          description={
            <DescriptionText>
              Você pode cancelar ou alterar as preferências da doação até a data de vencimento da
              fatura. Caso contrário, será alterada no próximo ciclo.
            </DescriptionText>
          }
        />
        <Description
          icon={<Credit />}
          title="A doação não abate no IRRF"
          description={
            <DescriptionText>
              Por ser uma doação opcional, não é abatida do valor do seu imposto de renda e não
              funciona para incentivos fiscais.
            </DescriptionText>
          }
        />
      </S.ContainerDescription>
      <S.WrapperFooter>
        <S.ContainerCheckBox>
          <Text variant="caption-1" style={{ marginRight: '17px' }}>
            Permito que, a cada cashback, o Inter faça transferências em meu nome para a{' '}
            {nameInstitution}, no valor escolhido.
          </Text>
          <Checkbox
            checked={terms.agree}
            onChange={() => setTerms((s) => ({ ...s, agree: !s.agree }))}
            style={{ marginLeft: '17px' }}
          />
        </S.ContainerCheckBox>
        <S.ContainerCheckBox>
          <Text variant="caption-1" style={{ marginRight: '17px' }}>
            Li e concordo com os{' '}
            <Text variant="caption-1" bold style={{ color: '#FF7A00' }} onClick={redirectToTerms}>
              Termos de Uso
            </Text>{' '}
            do Inter para doação recorrente de cashback
          </Text>

          <Checkbox
            checked={terms.read}
            onChange={() => {
              setTerms((s) => ({ ...s, read: !s.read }))
            }}
            style={{ marginLeft: '17px' }}
          />
        </S.ContainerCheckBox>
        <Button
          variant="primary"
          disabled={!terms.agree || !terms.read}
          onClick={() => setShowBottomSheetMessage(true)}
        >
          Ativar doação de cashback
        </Button>
        {isafeShowBottomSheet && (
          <ISafeBottomSheet
            onClose
            authenticate={iSafeAuthenticate}
            onComplete={onAuthenticateComplete}
            description="Para sua segurança estamos autenticando a operação com o i-safe."
            title="Autenticação"
          />
        )}

        {showBottomSheetMessage && (
          <BottomSheet onClose={() => setShowBottomSheetMessage(false)}>
            <S.ContainerTextModal>
              <Text variant="headline-h3" bold>
                Confirmar doação recorrente
              </Text>

              <Text variant="body-3" colorWeight={400} semiBold>
                Clique em confirmar para autenticar a doação recorrente pelo iSafe.
              </Text>

              <Button variant="primary" onClick={validationIsafe}>
                Confirmar
              </Button>
            </S.ContainerTextModal>
          </BottomSheet>
        )}
        {showBottomSheetMessageEnabled && (
          <BottomSheet onClose={() => setShowBottomSheetMessageEnabled(false)}>
            <S.ContainerTextModal>
              <Text variant="headline-h3" bold>
                Doação recorrente habilitada
              </Text>

              <Text variant="body-3" colorWeight={400} semiBold>
                A doação recorrente de cashback da fatura foi habilitada com sucesso.
              </Text>

              <Button variant="primary" onClick={redirectToSucess}>
                Confirmar
              </Button>
            </S.ContainerTextModal>
          </BottomSheet>
        )}
      </S.WrapperFooter>
    </S.Container>
  )
}
