import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

const FOOTER_HEIGHT = 79
export const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 0 0 0 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  padding-bottom: ${FOOTER_HEIGHT}px;
  ${headerStyles}
`

export const ContainerText = styled.div`
  padding: 30px 24px 32px 24px;
  text-align: center;
  span {
    color: #000000;
  }
`
export const ContainerTextModal = styled.div`
  display: flex;
  flex-flow: column wrap;

  text-align: left;
  span {
    text-align: left;
  }
  span + span {
    margin-top: 12px;
    color: #6a6c72;
  }
  button {
    margin-top: 32px;
  }
`

export const ContainerAlert = styled.div`
  padding-right: 24px;
  padding-left: 24px;
  margin-top: 24px;
`

export const ContainerDescription = styled.div`
  padding-bottom: 90px;
`

export const ContainerTextSecond = styled.div`
  padding: 19px 24px 0 24px;
  text-align: center;
  span {
    text-align: center;
    color: #6a6c72;
  }
`

export const ContainerCheckBox = styled.div`
  padding: 0 0 10px 0;
  text-align: justify;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #000000;
  }
`

export const WrapperFooter = styled.div`
  width: 100%;
  padding: 24px 24px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: var(--white100);
  button {
    width: 100%;
    max-height: 48px !important;
    height: 48px;
  }
`
