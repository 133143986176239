import React from 'react'

import { useHistory } from 'react-router-dom'

import { Text } from '@inter/inter-ui'
import { ReactComponent as Feedback } from '@/assets/feedback/error.svg'
import { Header } from '@/components'
import { RoutesNames } from '@/routes/routes'
import { BridgeService } from '@/services'
import { requestAnalytics } from '@/services/bridge'
import useGlobalState from '@/contexts/global-state'

import * as S from './styles'

export const FeedbackError = () => {
  const history = useHistory<{ skipOnboardingScreen?: boolean }>()
  const [{ appType }] = useGlobalState()
  const redirectToInformation = () => {
    requestAnalytics('C_DoacaoCashback_ValorDoacao_T_Faq', {
      content_id: appType || '',
    })
    history.push({
      pathname: RoutesNames.INFORMATION,
      search: `?date=${+new Date()}`,
    })
  }
  return (
    <S.Container>
      <Header
        onBackClick={() => {
          BridgeService.interWbNavigate.requestGoBack()
        }}
        onRightButtonClick={redirectToInformation}
        rightIcon="question-mark"
      >
        Doação de Cashback
      </Header>

      <S.ImageContainer>
        <Feedback />
      </S.ImageContainer>
      <S.TextContainer>
        <Text variant="headline-h1" colorWeight={500} semiBold>
          Houve um erro por aqui
        </Text>

        <Text variant="body-3" colorWeight={400} semiBold>
          No momento não foi possível ativar a doação recorrente de cashback. Por favor, retorne em
          alguns minutos.
        </Text>
      </S.TextContainer>

      <S.Footer>
        <S.Button
          onClick={() => {
            history.push({
              pathname: RoutesNames.ROOT,
              state: {
                skipOnboardingScreen: history.location.state?.skipOnboardingScreen || false,
              },
              search: `?date=${+new Date()}`,
            })
          }}
        >
          Tentar novamente
        </S.Button>
      </S.Footer>
    </S.Container>
  )
}
