import React from 'react'

import { useHistory } from 'react-router-dom'

import useGlobalState from '@/contexts/global-state'
import { Text } from '@inter/inter-ui'
import { ReactComponent as Feedback } from '@/assets/feedback/success.svg'
import { Header } from '@/components'
import { RoutesNames } from '@/routes/routes'
import { BridgeService } from '@/services'
import { requestAnalytics } from '@/services/bridge'
import { DeepLinks } from '@/services/bridge/types'

import * as S from './styles'

export const FeedbackCancel = () => {
  const history = useHistory<{ skipOnboardingScreen?: boolean }>()
  const [{ appType }] = useGlobalState()
  const redirectToHome = () => {
    history.push({
      pathname: RoutesNames.ROOT,
      search: `?date=${+new Date()}`,
    })
  }
  const redirectToInformation = () => {
    history.push({
      pathname: RoutesNames.INFORMATION,
      search: `?date=${+new Date()}`,
    })
  }
  const redirectToCard = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_DC_Feedback', {
      content_id: type,
      content_name: 'doacao cancelada',
    })
    BridgeService.navigateToNativeScreen(DeepLinks.CARD)
  }

  return (
    <S.Container>
      <Header
        onBackClick={redirectToHome}
        onRightButtonClick={redirectToInformation}
        rightIcon="question-mark"
      >
        Doação de Cashback
      </Header>

      <S.ImageContainer>
        <Feedback />
      </S.ImageContainer>
      <S.TextContainer>
        <Text variant="headline-h1" colorWeight={500} semiBold>
          Doação recorrente de cashback cancelada
        </Text>
        <Text variant="body-3" colorWeight={400} semiBold>
          Agradecemos por nos ajudar a impactar positivamente a vida de tantas pessoas.
        </Text>
        <Text variant="body-3" colorWeight={400} semiBold>
          Para reativar a sua doação de cashback com suas preferências, acesse a área de “Cartões” e
          vá em “Doação de cashback”.
        </Text>
      </S.TextContainer>

      <S.Footer>
        <S.Button onClick={redirectToCard}>Ir para a área de Cartões</S.Button>
      </S.Footer>
    </S.Container>
  )
}
