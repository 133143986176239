import React, { useState, useEffect, useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import { Text, Alert, Radio } from '@inter/inter-ui'
import { RoutesNames } from '@/routes/routes'
import { Header } from '@/components'
import useGlobalState from '@/contexts/global-state'
import { requestAnalytics } from '@/services/bridge'
import * as Actions from '@/contexts/global-state/reducer/actions'

import * as S from './styles'

export function EditInstitution() {
  const history = useHistory()
  const [, dispatch] = useGlobalState()
  const [selected, setSelected] = useState<number>()
  const [{ allInstitution, appType, userInstitution, nameInstitution }] = useGlobalState()

  interface IKeys {
    name: string
    description: string
    id: string
    url: string
  }
  const load = useCallback(
    (index: number, id: string, name: string, urls: string) => {
      setSelected(index)
      dispatch(Actions.setUserInstitution({ institution: id, name, url: urls }))
    },
    [dispatch],
  )
  useEffect(() => {
    if (allInstitution) {
      allInstitution.map((value: IKeys, index) =>
        userInstitution === value.id ? load(index, value.id, value.name, value.url) : null,
      )
    }
  }, [allInstitution, userInstitution, load])

  const redirectToAccept = () => {
    const type = String(appType)
    const name = String(nameInstitution)
    requestAnalytics('C_DoacaoCashback_T_Instituicao', {
      content_id: type,
      content_name: name,
    })
    history.push({
      pathname: RoutesNames.EDITACCEPT,
      search: `?date=${+new Date()}`,
    })
  }
  const redirectToInformation = () => {
    const type = String(appType)
    requestAnalytics('C_DoacaoCashback_ValorDoacao_T_Faq', {
      content_id: type,
    })
    history.push({
      pathname: RoutesNames.INFORMATION,
      search: `?date=${+new Date()}`,
    })
  }

  const redirectToHome = () => {
    history.push({
      pathname: RoutesNames.EDITCONFIGURATION,
      search: `?date=${+new Date()}`,
    })
  }

  const selectedRadio = (index: number, id: string, name: string, urls: string) => {
    setSelected(index)
    dispatch(Actions.setUserInstitution({ institution: id, name, url: urls }))
  }

  return (
    <S.Container>
      <Header
        onBackClick={redirectToHome}
        onRightButtonClick={redirectToInformation}
        rightIcon="question-mark"
      >
        Instituição
      </Header>

      <S.ContainerText>
        <Text variant="headline-h3" bold>
          Para qual instituição parceira você gostaria de doar?
        </Text>
      </S.ContainerText>
      <S.ContainerRadio>
        {allInstitution?.length !== undefined
          ? allInstitution.map((value: IKeys, index) => (
              <S.RadioBar key={value.id}>
                <S.ContainerTextRadio>
                  <Text variant="caption-1" colorWeight={500} bold style={{ fontSize: '13px' }}>
                    {value.name}
                  </Text>
                  <Text variant="caption-1" colorWeight={400} semiBold>
                    {value.description}
                  </Text>
                </S.ContainerTextRadio>
                <Radio
                  id={`simple-${index}`}
                  value="1"
                  reversed
                  checked={selected === index}
                  onChange={() => selectedRadio(index, value.id, value.name, value.url)}
                />
              </S.RadioBar>
            ))
          : null}
      </S.ContainerRadio>

      <S.WrapperFooter>
        <S.ContainerAlert>
          <Alert type="alert" icon="warning">
            Caso a instituição descontinue a parceria, você será informado e solicitaremos a escolha
            de outra.
          </Alert>
        </S.ContainerAlert>
        <S.Button onClick={redirectToAccept}>Continuar</S.Button>
      </S.WrapperFooter>
    </S.Container>
  )
}
