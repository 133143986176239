import styled from 'styled-components'

import { Button as UIButton } from '@inter/inter-ui'
import { headerStyles } from '@/components/Header/styles'
import { ReactComponent as InterLogo } from '@/assets/terms/inter-logo.svg'

export const Container = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 720px 80px;
  background: #ff7a00;

  ${() => headerStyles({ additionalPadding: 6 })}
`

export const HeaderContainer = styled.div`
  height: 100%;
`

export const ContentContainer = styled.div`
  margin: 0 0px 80px 0;
  padding: 0 8px 100px 0;

  & h1 {
    font-size: 14px;
    margin: 16px 0;
  }
  & p {
    margin: 16px 0;
  }
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray400);
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: var(--gray200);
    border-radius: 8px;
  }
`

export const FooterContainer = styled.div`
  align-items: center;
  background: var(--white100);
  border-top: 1px solid var(--gray100);
  bottom: 0;
  display: flex;
  height: 80px;
  justify-content: center;
  position: fixed;
  width: 100%;
`
export const Button = styled(UIButton)`
  width: 86%;
  font-size: 14px;
  font-weight: bold;
`
export const Logo = styled(InterLogo)`
  display: block;
  height: 28px;
  margin-bottom: 16px;
  width: 78px;
`

export const ContainerDate = styled.div`
  width: 211px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-radius: 20px;
  height: 32px;
  border: 1px solid #d3d3d3;
  margin-top: 24px;
  margin-bottom: 16px;
  margin-left: 32px;
  background-color: var(--white100);
  span {
    color: #000000;
  }
  svg {
    margin-left: 4px;
  }
`
export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: left;
  text-align: left;
  margin-bottom: 16px;
  span {
  }
  span + span {
    margin-top: 16px;
    text-align: left;
  }
  a {
    color: #ff7a00;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
