import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

const FOOTER_HEIGHT = 72

export const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 0 0;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--white100);
  padding-bottom: ${FOOTER_HEIGHT}px;
  ${headerStyles}
`
export const ContainerTextModal = styled.div`
  display: flex;
  flex-flow: column wrap;

  text-align: left;
  span {
    text-align: left;
  }
  span + span {
    margin-top: 12px;
    color: #6a6c72;
  }
  button {
    margin-top: 32px;
    background: #ff7a00;
  }
`

export const TextContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  text-align: center;
  margin-top: 24px;
  padding-bottom: 80px;
  span + span {
    margin-top: 8px;
    text-align: center;
  }
  span + span + span {
    margin-top: 10px;
    text-align: center;
  }
  a {
    color: #ff7a00;
  }
`
export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  svg {
    width: 100%;
    height: 100%;
  }
`

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column wrap;
  padding: 24px 24px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: var(--white100);
  button {
    width: 100%;
  }
  button + button {
    margin-top: 10px;
  }
`

export const Emphasis = styled.span`
  width: auto;
  text-decoration: none;
  color: #6a6c72;
  font-weight: bold;
  outline: none;
  background-color: transparent;
`

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
`
