import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

const FOOTER_HEIGHT = 79
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: ${FOOTER_HEIGHT}px;
  ${headerStyles}
`

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
`

export const TextContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  span + span {
    margin-top: 8px;
    text-align: center;
  }
`

export const Footer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 24px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: var(--white100);
`

export const Button = styled.button`
  width: 100%;
  color: #ffffff;
  background: #ff7a00;
  height: 48px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
`
